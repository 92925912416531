<template>
    <div>
        <el-form
            :model="formData"
            :rules="formRule"
            ref="dataForm"
            size="mini"
            :disabled="type === 'detail'"
            label-width="120px"
            @keyup.enter.native="dataFormSubmit()"
        >
            <el-row :gutter="24">
                <el-col :span="20">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="20">
                    <el-form-item label="组件" prop="component">
                        <el-input v-model="formData.component"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="20">
                    <el-form-item label="权限" prop="auth">
                        <el-input v-model="formData.auth"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="20">
                    <el-form-item label="描述" prop="description">
                        <el-input type="textarea" v-model="formData.description"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4"></el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'add'
        }
    },
    data() {
        return {
            formData: {
                id: null,
                name: '',
                component: '',
                description: '',
                auth: '',
            },
            formRule: {
                name: [
                    {required: true, message: '名称不能为空', trigger: 'blur'},
                ],
                component: [
                    {required: true, message: '组件不能为空', trigger: 'blur'}
                ]
            },
        };
    },
    methods: {
        dataFormSubmit(callback) {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        this.$client.updateWidget(this.formData.id, this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success'
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning'
                                });
                            }
                        });
                    } else {
                        this.$client.insertWidget(this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success'
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning'
                                });
                            }
                        });
                    }
                }
            });
        },
    },
    created() {
        if (this.id) {
            this.$client.getWidget(this.id).then((ret) => {
                if (ret.code === 0) {
                    const formData = ret.data || {};
                    if (!formData.transformerEntity) {
                        formData.transformerEntity = {};
                    }
                    if (!formData.highUnitEntity) {
                        formData.highUnitEntity = {};
                    }
                    if (!formData.lowUnitEntity) {
                        formData.lowUnitEntity = {};
                    }
                    this.formData = formData;
                    this.getHighUnitEquipments();
                    this.getLowUnitBoxes();
                } else {
                    this.$message({
                        message: ret.msg,
                        type: 'warning'
                    });
                }
            });
        }
    }
};
</script>
