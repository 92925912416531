<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions">
            <el-button type="primary" size="mini" @click="openAddOrUpdatePage(0)" v-if="$authCheck('sys:widget:save')">新增</el-button>
            <!-- <el-button type="primary" size="mini" @click="openAddOrUpdatePage(1)">修改</el-button> -->
            <el-button type="primary" size="mini" @click="deleteHandle()" v-if="$authCheck('sys:widget:delete')">删除</el-button>
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.pageWidget"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @selection-change="selectHandle"
            >
            <template slot="append">
                <el-table-column
                    align="center"
                    label="操作"
                    type="action"
                    width="50"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-tooltip content="修改" placement="top" :open-delay="200" v-if="$authCheck('sys:widget:update')">
                            <div
                                class="search-table-icon search-table-icon_edit"
                                @click="handleEdit(scope.row)"
                                type="text"
                            ></div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </template>
            </ex-search-table-pagination>
        </div>
        <div class="ui-layout-content--full">
            <!--新增 / 修改-->
            <el-dialog
                v-bind="addOrUpdateDialog"
                width="520px"
                :title="addOrUpdateDialog.title"
                v-if="addOrUpdateDialog.visible"
                :visible.sync="addOrUpdateDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <add-or-update ref="AddOrUpdate" :id="addOrUpdateDialog.currentId" :type="addOrUpdateDialog.type"></add-or-update>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addOrUpdateDialog.visible = false" size="mini">取消</el-button>
                    <el-button type="primary" @click="save" size="mini">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import AddOrUpdate from './AddOrUpdate';

export default {
    data() {
        return {
            selectedRecords: null,
            addOrUpdateDialog: {
                visible: false,
                title: '',
                currentId: '',
                type: '',
            },
        };
    },
    computed: {
        columns() {
            return [
                {
                    type: 'selection',
                },
                {
                    prop: 'name',
                    label: '组件名称',
                },
                {
                    prop: 'component',
                    label: '组件',
                },
                {
                    prop: 'description',
                    label: '描述',
                },
                {
                    prop: 'createTime',
                    label: '创建时间',
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'name',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入组件名称',
                        label: '',
                    },
                ],
            };
        },
    },
    components: {
        AddOrUpdate
    },
    methods: {
        //新增 / 修改 页面
        openAddOrUpdatePage(status) {
            //默认新增 status=1时修改
            let id = null;
            let title = '新增';
            let type = 'add';
            if (status) {
                //修改
                if (!this.preValidate(1)) {
                    return;
                }
                id = this.selectedRecords[0].id;
                title = '修改';
                type = 'edit';
            }
            this.addOrUpdateDialog = {
                title,
                currentId: id,
                visible: true,
                type,
            };
        },
        handleEdit(row) {
            this.addOrUpdateDialog = {
                title: '修改',
                currentId: row.id,
                visible: true,
                type: 'edit',
            };
        },
        // 新增 / 修改
        save() {
            this.$refs.AddOrUpdate.dataFormSubmit((rest) => {
                this.addOrUpdateDialog.visible = false;
                this.$refs.searchTable.searchHandler(rest);
            });
        },
        // 删除
        deleteHandle() {
            if (!this.preValidate()) {
                return;
            }
            let ids = [];
            this.selectedRecords.forEach(function(item) {
                ids.push(item.id);
            });
            this.$confirm(`确定删除选中组件`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.batchDeleteWidget(ids).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.$refs.searchTable.searchHandler();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            }).catch(() => {});
        },
        selectHandle(val) {
            this.selectedRecords = val;
        },
        preValidate(isSingle) {
            if (this.selectedRecords == null || this.selectedRecords.length < 1) {
                this.$message({
                    type: 'warning',
                    message: '请先选择待操作数据'
                });
                return false;
            }
            //是否只能选一条数据的校验
            if (isSingle) {
                if (this.selectedRecords.length > 1) {
                    this.$message({
                        type: 'warning',
                        message: '只允许对一条进行操作'
                    });
                    return false;
                }
            }
            return true;
        },
    }
};
</script>
